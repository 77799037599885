.dropbtn {
  background-color: transparent;
  color: var(--trs-gray-10);
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.dropbtn:focus {
  background-color: var(--trs-gray-98);
}

.dropbtn img {
  width: 24px;
  height: 24px;
}

.userName {
  line-height: 24px;
}

.dropdown {
  position: relative;
  float: right;
}

.dropdownContent {
  display: block;
  position: absolute;
  background-color: var(--trs-gray-98);
  width: 150px;
  overflow: auto;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdownContent a {
  font-weight: 400;
  font-size: 16px;
  color: var(--trs-destructive-red);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: var(--trs-gray-90);
}

.menuArrows {
  float: right;
  height: 24px;
}

.menuProfile {
  height: 24px;
}
