.resultGrid {
  display: grid;
  gap: 20px;
  padding-top: 20px;
  grid-template-columns: 1fr;
}

@media (min-width: 1100px) {
  .resultGrid {
    grid-template-columns: auto minmax(280px, 28%);
  }
}

.resultGridAside {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: max-content;
}

@media print {
  .resultGrid {
    display: none;
  }
}
