.pipeTable {
  width: 95%;
  background-color: var(--trs-gray-98);
}

.alignLeft {
  text-align: left;
}

.pipeTable th,
.pipeTable td {
  padding: 4px 16px;
}

.alignRight {
  text-align: right;
}
