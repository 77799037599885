.calcSummaryContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--trs-white);
  border: 1px solid var(--trs-gray-98);
  box-sizing: border-box;
}

.donutContainer {
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.donutTotalsLabel {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: var(--trs-gray-10);
  font-size: 14px;
}

.donutTotalsValue {
  position: absolute;
  top: 33%;
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  color: var(--trs-gray-10);
  left: 50%;
  transform: translate(-50%, -50%);
}

.runtimeContainer {
  position: absolute;
  top: 57%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.runtimeContainer img {
  width: 32px;
  height: 32px;
  margin: 0 auto;
}

.runtimeText {
  font-weight: bold;
  font-size: 14px;
  color: var(--trs-gray-10);
  text-align: center;
}

.circle {
  width: 9px;
  height: 9px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: sub;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.purpleCircle {
  background-color: var(--trs-purple-30);
}

.orangeCircle {
  background-color: var(--trs-orange-60);
}

.greenCircle {
  background-color: var(--trs-green-30);
}

.blueCircle {
  background-color: var(--trs-blue-40);
}

.yellowCircle {
  background-color: var(--trs-yellow-60);
}

.redCircle {
  background-color: #ba312b;
}

.pinkCircle {
  background-color: #e8919c;
}

.loadTypeContainer {
  padding: 20px 25px;
  background-color: white;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  column-gap: 15px;
  row-gap: 16px;
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--trs-gray-30);
}

.loadTypeHeading {
  background-color: var(--trs-gray-95);
  padding: 10px 25px;
  grid-template-rows: auto;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--trs-black);
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.alignLeft {
  text-align: left;
}

.loadTypeTotals {
  font-weight: bold;
  background-color: var(--trs-gray-98);
  border-top: 1px solid var(--trs-gray-90);
  border-bottom: 1px solid var(--trs-gray-90);
  grid-template-rows: auto;
  padding: 10px 25px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.safetyContainer {
  padding: 10px 25px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 30px;
  row-gap: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.totalsLabel {
  text-align: left;
  color: var(--trs-gray-10);
}

.totalsValue {
  text-align: right;
  color: var(--trs-gray-10);
}

.finalTotals {
  font-size: 14px;
  font-weight: 700;
}

.spanTwoColumns {
  grid-column-start: 1;
  grid-column-end: 3;
}

.finalTotalContainer {
  padding: 23px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  column-gap: 30px;
  background-color: var(--trs-gray-95);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.finalTotalValue {
  font-size: 18px;
}

.runtimePrintDisplay {
  display: none;
}

@media (min-width: 1200px) {
  .calcSummaryContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .donutTotalsLabel {
    top: 45%;
  }

  .donutTotalsValue {
    top: 38%;
  }
}

@media print {
  .loadTypeContainer {
    padding: 15px 20px;
    column-gap: 0px;
  }
  .finalTotalContainer,
  .loadTypeHeading {
    border-width: 1px 0;
    border-style: solid;
    border-color: #b3b3b3;
  }

  .runtimePrintDisplay {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
  }

  .donutContainer {
    background-color: var(--trs-gray-98);
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
