.roomPreviewContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--trs-white);
  border: 1px solid var(--trs-gray-98);
  box-sizing: border-box;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.canvasContainer {
  background-color: var(--trs-gray-98);
  padding: 10px;
  align-items: center;
  text-align: center;
}

.roomCanvas {
  width: 340px;
  height: 340px;
}

.totalsContainer {
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 30px;
  row-gap: 16px;
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  color: var(--trs-gray-10);
}

.roomDetailsPrint {
  display: none;
}

@media (min-width: 1200px) {
  .roomPreviewContainer {
    display: flex;
    flex-direction: row;
  }

  .totalsContainer {
    padding: 0 0 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:
      minmax(min-content, max-content)
      minmax(min-content, max-content);
    column-gap: 110px;
    row-gap: 16px;
    align-items: flex-start;
  }
}

@media print {
  .roomPreviewContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .totalsContainer {
    row-gap: 8px;
    column-gap: 15px;
    padding-left: 70px;
    padding-right: 0px;
    background-color: var(--trs-gray-98);
  }

  .labelPrint {
    font-weight: bold;
  }

  .roomDetailsPrint {
    display: block;
  }
}
