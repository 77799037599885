:root {
  --system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* UGLY HACK VALUES */
  --reece-footer-excess-whitespace-height: -50px;

  /* Breakpoints */
  --breakpoints-sm: 576px; /* max-width for phones/small devices and min-width for tablets in portrait*/
  --breakpoints-md: 768px; /* min-width for tablets on landscape */
  --breakpoints-lg: 992px; /* min-width for laptops/desktops */
  --breakpoints-xl: 1200px;

  /* TRS Application Colours */
  --trs-default-blue: #002f56;
  --trs-training-orange: #f25b21;
  --trs-sales-order-blue: #347eb7;
  --trs-customer-order-purple: #8956a0;
  --trs-qoute-release-green: #369684;
  --trs-black: #000000;
  --trs-gray-10: #1a1a1a;
  --trs-gray-20: #333333;
  --trs-gray-30: #4d4d4d;
  --trs-gray-40: #666666;
  --trs-gray-50: #808080;
  --trs-gray-60: #999999;
  --trs-gray-70: #b2b2b2;
  --trs-gray-80: #cccccc;
  --trs-gray-90: #e5e5e5;
  --trs-gray-95: #f2f2f2;
  --trs-gray-96: #f5f5f5;
  --trs-gray-98: #fafafa;
  --trs-white: #ffffff;
  --trs-destructive-red: #d00000;
  --trs-primary-action-blue: #0063b5;
  --trs-orange-60: #eb7e47;
  --trs-yellow-60: #ebc247;
  --trs-green-30: #1c7d44;
  --trs-green-40: #25a75c;
  --trs-green-60: #59d98e;
  --trs-blue-40: #1481b8;
  --trs-blue-50: #269dd9;
  --trs-blue-60: #47bfeb;
  --trs-purple-40: #773399;
  --trs-purple-30: #5c1f7a;
  --trs-font-family: "Open Sans", var(--system-font-stack);
  --trs-font-family-alt: "Open Sans", var(--system-font-stack);
  --trs-primary-action-blue-hover: #005297;

  /* Brand */
  --brand-reece-secondary-blue: #0776aa;
  --brand-reece-primary-blue: #00497a;
  --brand-reece-primary-blue-transparent: rgba(0, 73, 122, 0.2);
  --brand-reece-secondary-orange: #fc921f;
  --brand-reece-primary-orange: #f7991c;
  --brand-reece-primary-orange-dark: #e2870f;
  --brand-reece-top-bar-gray: #f4f4f4;
  --brand-reece-font-family: "Gotham A", "Gotham B", var(--system-font-stack);
  --brand-reece-font-family-alt: "Gotham B", "Gotham A",
    var(--system-font-stack);

  /* Backgrounds */
  --background-color-faint: #f4f4f4;
  --background-color-light: #e9e9e9;
  --background-color-medium: #d6d6d6;

  /* Borders */
  --border-color-light: #e9e9e9;
  --border-color-medium: #d6d6d6;
  --border-color-dark: #222222;
  --divider-color: #d6d6d6;

  /* Shadows */
  --box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  /* Text + Fonts */
  --text-color-dark: #222222;
  --text-color-medium: #666666;
  --text-color-light: #999999;
  --text-color-lighter: #d6d6d6;
  --text-color-faint: #e9e9e9;
  --text-color-white: #ffffff;
  --text-color-error: #c91d1d;
  --text-color-warning: #b38900;
  --text-color-info: var(--brand-reece-secondary-blue);
  --text-color-grey2: #4d4d4d;
  --text-color-grey: #3f3f3f;

  /* Buttons */
  --text-button-disabled-opacity: 0.4;

  /* Spacing */
  --spacing-horizontal-xx-small: 0.25rem;
  --spacing-horizontal-x-small: 0.45rem;
  --spacing-horizontal-small: 1rem;
  --spacing-horizontal: 1.5rem;
  --spacing-horizontal-large: 1.7rem;
  --spacing-horizontal-xxx-large: 4rem;
  --spacing-vertical-tiny: 0.45rem;
  --spacing-vertical-small: 1rem;
  --spacing-vertical: 1.5rem;
  --spacing-vertical-large: 1.9rem;
  --spacing-vertical-xxx-large: 4.25rem;

  /* Tab Navigation */
  --tab-navigation-text-color: var(--text-color-light);
  --tab-navigation-active-text-color: var(--text-color-dark);

  /* Form elements */
  --element-border-color: #ddd;

  /* Alert messages */
  --alert-warning-background: #fefcf6;
  --alert-error-background: #fff5f5;
  --alert-info-background: #f6fbfd;
  --alert-success-background: #f7fdf9;

  --info-soft-pink: #e8919c;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root,
html,
body {
  height: 100%;
  margin: 0;
}

#root {
  margin-bottom: var(--reece-footer-excess-whitespace-height);
  height: 100%;
}

body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif,
    var(--system-font-stack);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--trs-gray-10);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: "Open Sans", Arial, Helvetica, sans-serif,
    var(--system-font-stack);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  color: var(--trs-gray-10);
}

h1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 131px;
  letter-spacing: -1.5px;
}

h2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 82px;
  letter-spacing: 0em;
}

h3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
}

h4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.25px;
}

h5 {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
}

h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

a,
a:hover {
  text-decoration: none;
}

.section-header {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  margin: 16px 0;
}

.section-title {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin-bottom: 16px;
}

.section-description {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  margin-bottom: 16px;
}

.small-label-text {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--trs-gray-30);
}

label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.labelBoldText {
  font-weight: 600;
}

.heatLoadDetailsTransmissionBorder {
  border-left: 10px var(--trs-orange-60) solid;
}

.heatLoadDetailsInfiltrationBorder {
  border-left: 10px var(--trs-green-30) solid;
}

.heatLoadDetailsProductBorder {
  border-left: 10px var(--trs-purple-30) solid;
}

.heatLoadDetailsAdditionalLoadsBorder {
  border-left: 10px var(--trs-blue-40) solid;
}

.heatLoadDetailsOtherMiscLoadsBorder {
  border-left: 10px var(--info-soft-pink) solid;
}

.page-header-container {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--trs-gray-98);
  display: flex;
  align-items: center;
  padding: 16px 0 16px 4px;
}

.printButtonContainer {
  flex-grow: 4;
}

.buttonContents {
  float: right;
  display: inline-flex;
}

.shadow {
  position: sticky;
  top: 89px;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 2px 2px 1px var(--trs-gray-80);
}

.cover {
  position: absolute;
  background: var(--trs-gray-98);
  width: 100%;
  height: 8px;
  z-index: 1;
}

@media print {
  body {
    margin: 0;
    background-color: white;
  }

  .page-header-container {
    display: none;
  }

  .section-header {
    display: none;
  }

  .hideSectionForPrint {
    display: none;
  }

  .shadow {
    display: none;
  }

  .cover {
    display: none;
  }

  .page-break {
    page-break-after: avoid;
    page-break-before: always;
    align-content: center;
  }

  .page-header {
    font-size: 22px;
    line-height: 125%;
    font-weight: 600;
    padding-top: 16px;
    clear: both;
  }

  ._hj_feedback_container {
    display: none;
  }
}
