.appContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto 1fr;
  column-gap: 8px;
  height: 100%;
}

.applicationBody {
  grid-column: 1 / 5;
  padding: 16px 8px;
  background-color: var(--trs-gray-98);
  height: 100%;
}

.applicationHeader,
.appNavigationBar {
  grid-column: 1 / 5;
}

.contentContainer,
.pageTitleContainer {
  max-width: 100vw;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .appContainer {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 16px;
  }

  .applicationHeader,
  .appNavigationBar {
    grid-column: 1 / 13;
  }

  .applicationBody {
    grid-column: 1 / 13;
    padding: 32px 16px;
  }

  .contentContainer {
    max-width: 990px;
    margin: 0 auto;
  }

  .pageTitleContainer {
    max-width: 1000px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .appContainer {
    column-gap: 32px;
  }

  .contentContainer {
    max-width: 1340px;
    margin: 0 auto;
  }

  .pageTitleContainer {
    max-width: 1350px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 auto;
  }
}

@media (min-width: 1400px) {
  .applicationBody {
    grid-column: 1 / 13;
    padding: 32px 0px;
  }
}

@media print {
  .applicationBody {
    padding: 0 0;
    background-color: white;
  }
}
