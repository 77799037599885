.errorInputState {
  border: 1px solid var(--trs-destructive-red);
  border-radius: 4px;
}

.initialLabelState {
  color: var(--trs-green-30);
}

.initialLabelState:active,
.initialLabelState:focus {
  outline-color: transparent;
  outline-width: 0;
}

.initialSelectState {
  color: var(--trs-gray-30);
  border: 1px solid var(--trs-green-30) !important;
}

.initialSelectState:active,
.initialSelectState:focus {
  outline-color: transparent;
  outline-width: 0;
}

.selectContainer {
  margin-bottom: 16px;
}

.selectXSmall {
  width: 25%;
  max-width: 75px;
  margin-right: 20px;
}

.selectSmall {
  width: 33%;
  max-width: 120px;
  margin-right: 20px;
}

.selectMedium {
  width: 50%;
  max-width: 190px;
  margin-right: 20px;
}

.selectLarge {
  width: 75%;
  max-width: 305px;
  margin-right: 20px;
}

.selectXLarge {
  width: 100%;
  max-width: 400px;
}

.selectStyle {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 4px;
  padding: 8px;
  height: 38px;
  background: var(--trs-white);
  border: 1px solid var(--trs-gray-80);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../../assets/down_arrow_black.svg") 98% 50% no-repeat #fff;
}

.errorState {
  border: 1px solid var(--trs-destructive-red);
  border-radius: 4px;
  color: var(--trs-destructive-red);
}

.errorTextState {
  color: var(--trs-destructive-red);
}
