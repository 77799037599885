.combinedContainer {
  display: flex;
}

.equipmentContainer {
  background-color: var(--trs-gray-98);
  width: 50%;
  padding: 20px;
  margin-top: 10px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  clear: both;
}

.imageContainer {
  padding: 5px;
}

.image {
  max-width: 280px;
}

.productTitle {
  font-size: 14px;
  font-weight: 700;
}
