.header {
  width: 100%;
  border-bottom: solid 4px #d7282f;
  padding: 15px 0;
}

.testHeader {
  background-color: #f4ecf9;
  border-bottom: solid 4px var(--trs-customer-order-purple);
}

.stagingHeader {
  background-color: #fadfd1;
  border-bottom: solid 4px var(--trs-training-orange);
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
}

.heading {
  margin: 8px 0;
}

.logoContainer {
  margin: auto 17px auto 0;
}

.logoContainer img {
  width: 58px;
}

.userMenu {
  align-self: center;
  flex-grow: 3;
}

.userMenu a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--trs-destructive-red);
}

@media print {
  .header {
    display: none;
  }
}
