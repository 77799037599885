.circleContainer {
  width: 380px;
  height: 380px;
  min-width: 380px;
  display: block;
}

@media print {
  .circleContainer {
    display: block;
  }
}
