.heatLoadContainer {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: flex-start;
}

.narrowFormContainer {
  flex-direction: column;
}

.deleteButtonContainer {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .summaryContainer {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 90px;
  }

  .addButtonContainer {
    display: flex;
    justify-content: left;
  }
}

@media (min-width: 769px) {
  .heatLoadContainer {
    grid-template-columns: minmax(380px, 440px) minmax(min-content, 380px);
    grid-template-rows: auto;
    column-gap: 16px;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .heatLoadContainer {
    grid-template-columns: minmax(380px, 440px) minmax(min-content, 580px);
    grid-template-rows: auto;
    column-gap: 16px;
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .heatLoadContainer {
    grid-template-columns: minmax(360px, 440px) minmax(min-content, 880px);
    grid-template-rows: auto;
    column-gap: 16px;
    align-items: flex-start;
  }

  .deleteButtonContainer {
    display: flex;
    align-items: center;
    padding-top: 15px;
  }
}

@media print {
  .heatLoadContainer {
    display: none;
  }
}
