.switch {
  background-color: var(--trs-gray-90);
  border-radius: 4px;
  position: relative;
  padding: 2px;
  margin-top: 4px;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
}

.switchContainer {
  display: flex;
  align-items: baseline;
  margin-top: 3px;
  margin-bottom: 16px;
}

.switchContainer label {
  flex-grow: 1;
  flex-basis: auto;
}

.switch label {
  display: inline-block;
  flex-grow: 1;
  flex-basis: auto;
  background-color: transparent;
  color: var(--trs-gray-30);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 8px 8px;
  transition: all 0.4s ease;
}

.switch input {
  display: none;
}

.switch input:checked + label {
  background-color: var(--trs-white);
  color: var(--trs-gray-30);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.4s ease;
}

@media (min-width: 576px) {
  .switchContainer {
    flex-direction: column;
    align-items: unset;
  }
}
