.footerContainer {
  width: 100vw;
  height: 50px;
  border-top: solid 1px black;
  padding: 0 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 125%;
  position: fixed;
  bottom: 0;
}
