.navigationBarContainer {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
  padding: 16px 16px;
  height: 51px;
  vertical-align: middle;
}

.navigationLink {
  color: var(--trs-primary-action-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  background-color: white;
  border: 0;
  padding-left: 0;
}

.linkText {
  vertical-align: top;
}

@media print {
  .navigationBarContainer {
    display: none;
  }
}
