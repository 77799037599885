.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  column-gap: 15px;
}

@media (min-width: 768px) {
  .gridContainer {
    column-gap: 25px;
  }
}

@media (min-width: 992px) {
  .gridContainer {
    column-gap: 50px;
  }
}

@media print {
  .gridContainer {
    display: none;
  }
}
