.primaryButton,
.secondaryButton,
.destructiveButton,
.secondaryRedButton {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  border: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  height: 43px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 5px;
  padding: 8px 16px;
}

.primaryButton {
  color: white;
  background-color: var(--trs-primary-action-blue);
}

.primaryButton:hover {
  background-color: var(--trs-primary-action-blue-hover);
}

.primaryButton:active,
.primaryButton:focus {
  background-color: var(--trs-primary-action-blue);
}

.primaryButton:disabled {
  background-color: var(--trs-gray-90);
  color: var(--trs-gray-50);
  cursor: not-allowed;
}

.secondaryButton {
  background-color: white;
  border: 2px solid var(--trs-gray-10);
  color: var(--trs-gray-10);
}

.secondaryRedButton {
  background-color: white;
  border: 1px solid var(--trs-gray-80);
  color: var(--trs-destructive-red);
}

.secondaryButton:hover,
.secondaryButton:active {
  color: var(--trs-primary-action-blue);
  border-color: var(--trs-primary-action-blue);
}

.secondaryRedButton:hover,
.secondaryRedButton:active {
  background-color: var(--trs-gray-98);
  border: 0;
}

.secondaryButton:disabled,
.secondaryRedButton:disabled {
  background-color: white;
  border: 2px solid var(--trs-gray-90);
  color: var(--trs-gray-70);
  cursor: not-allowed;
}

.destructiveButton {
  color: white;
  background-color: var(--trs-destructive-red);
}

.destructiveButton:hover {
  background-color: rgba(208, 0, 0, 0.8);
}

.destructiveButton:focus {
  font-weight: 700;
  border: 4px solid white;
}

.destructiveButton:active {
  font-weight: 700;
  background-color: var(--trs-destructive-red);
}

.destructiveButton:disabled {
  background-color: var(--trs-destructive-red);
  cursor: not-allowed;
  opacity: 0.4;
}

@media (min-width: 576px) {
  .primaryButton,
  .secondaryButton,
  .destructiveButton,
  .secondaryRedButton {
    width: unset;
    min-width: 180px;
  }
}

@media print {
  .primaryButton,
  .secondaryButton,
  .destructiveButton,
  .secondaryRedButton {
    display: none;
  }
}
