.printLayoutContainer {
  display: none;
}

.overridePrintLayoutContainer {
  display: block;
}

.tablePrint {
  width: 100%;
}

@page {
  margin: 6mm;
}

.avoidPageBreak {
  break-inside: avoid;
}

@media print {
  table,
  img,
  svg {
    break-inside: avoid;
  }

  .printLayoutContainer {
    display: block;
    visibility: visible;
    background-color: white;
  }

  .pageFooterSpace {
    height: 50px;
  }

  .pageHeaderSpace {
    height: 100px;
  }

  table {
    font-size: 13px;
    line-height: 125%;
  }

  .tablePrint {
    width: 100%;
  }

  .pageTitleContainer {
    display: flex;
    width: 100vw;
    padding-bottom: 12px;
  }

  .subHeading {
    font-size: 13px;
    line-height: 125%;
    line-height: 16px;
    font-weight: 600;
  }

  .totalsContainer {
    flex-grow: 4;
    font-size: 13px;
    line-height: 125%;
    text-align: right;
    padding-top: 16px;
  }

  .totalsHeading {
    line-height: 125%;
    font-size: 32px;
    font-weight: 700;
  }

  .tableHalfWidth {
    width: 50%;
    float: left;
  }

  td {
    vertical-align: top;
    padding: 2px 2px;
    line-height: 125%;
  }

  .indentTableCell {
    padding-left: 5px;
  }

  .tableTitle {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }
}
