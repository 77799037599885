.liquidFieldsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  column-gap: 10px;
}

.alertMessageContainer {
  margin-bottom: 20px;
}

.subHeadingLineLengths {
  font-size: 14px;
  font-weight: bold;
}
