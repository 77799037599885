.printHeaderContainer {
  width: 99vw;
  height: 100px;
  position: fixed;
  top: 0;
  border-bottom: solid 1px black;
  padding: 15px 0;
}

.container {
  padding: 0 0px;
  display: flex;
  flex-direction: row;
}

.logoContainer {
  margin: auto 17px auto 0;
}

.logoContainer img {
  width: 180px;
}

.printTitle {
  align-self: center;
  flex-grow: 3;
}

.titleHeaderTextContainer {
  float: right;
}

.titleHeader {
  font-size: 24px;
  line-height: 125%;
  font-weight: 600;
  text-align: right;
}

.titleInfoText {
  font-size: 13px;
  line-height: 125%;
  font-weight: 400;
  text-align: right;
}
